<template>
  <div class="index">
    <div class="head">
      <div class="logo_content">
        <div class="logo">
          <img src="../assets/logo@1x.png" alt="" />
        </div>
        <div class="logo_text">
          <img src="../assets/logo_text@1x.png" alt="" />
        </div>
      </div>
      <div class="nav">
        <div
          class="nav_func"
          @mouseenter="active(0)"
          @mouseleave="unActive(0)"
          :class="{ active: active_tab == 0 }"
          @click="download"
        >
          <span>客户端下载</span>
        </div>
        <div
          class="nav_func"
          @mouseenter="active(1)"
          @mouseleave="unActive(1)"
          :class="{ active: active_tab == 1 }"
          @click="help"
        >
          <span>使用手册</span>
        </div>
        <div
          class="nav_func"
          @mouseenter="active(2)"
          @mouseleave="unActive(2)"
          :class="{ active: active_tab == 2 }"
          @click="tool"
        >
          <span>淘客工具箱</span>
        </div>
        <div
          class="nav_func"
          @mouseenter="active(3)"
          @mouseleave="unActive(3)"
          :class="{ active: active_tab == 3 }"
          @click="agent"
        >
          <span>申请代理</span>
        </div>
        <div
          class="nav_func"
          @mouseenter="active(4)"
          @mouseleave="unActive(4)"
          :class="{ active: active_tab == 4 }"
          @click="openSource"
        >
          <span>开放平台</span>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="center_left">
        <div class="up">
          <div class="jtb">
            <span>
              精推宝-专注服务于淘客社群运营的工具助手
            </span>
          </div>
        </div>
        <div class="down">
          <div class="test">
            <div class="test_button" @click="download">
              <span>开始试用</span>
            </div>
            <div class="test_text">
              <p>
                简单、实用 <br />
                极致、体验 <br />
                高效、提升 <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="center_right">
        <el-image
          style="width: 100%; height: 100%"
          :src="require('../assets/background.png')"
          fit="scale-down"
        ></el-image>
      </div>
    </div>
    <div class="footer">
      <div class="detail_list">
        <div class="item">
          <div class="item_icon">
            <el-image
              style="width: 100%; height: 100%"
              :src="require('../assets/brand_play_store@1x.png')"
              fit="scale-down"
            ></el-image>
          </div>
          <div class="item_text">
            <div class="item_text_title">
              <span>员工（代理）绩效统计</span>
            </div>
            <div class="item_text_content">
              <span>分组、商品、多维度数据分析</span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item_icon">
            <el-image
              style="width: 100%; height: 100%"
              :src="require('../assets/brand_linkpath@1x.png')"
              fit="scale-down"
            ></el-image>
          </div>
          <div class="item_text">
            <div class="item_text_title">
              <span>微信内一键领取专属口令</span>
            </div>
            <div class="item_text_content">
              <span>添加、绑定、领取只需三步</span>
            </div>
          </div>
        </div>

        <!-- <div class="item">
                <div class="item_icon">
                    <el-image style="width: 100%; height: 100%" :src="require('../assets/brand_trello@1x.png')" fit="scale-down"></el-image>

                </div>
                <div class="item_text">
                    <div class="item_text_title">
                        <span>管理员视角纵览全局</span>
                    </div>
                    <div class="item_text_content">
                        <span>随时随地浏览所有订单、员工维度数据</span>
                    </div>
                </div>
            </div> -->
        <div class="item">
          <div class="item_icon">
            <el-image
              style="width: 100%; height: 100%"
              :src="require('../assets/brand_unsplash@1x.png')"
              fit="scale-down"
            ></el-image>
          </div>
          <div class="item_text">
            <div class="item_text_title">
              <span>高效抢淘礼金绿色通道</span>
            </div>
            <div class="item_text_content">
              <span>命中率远远高于市场同类产品</span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item_icon">
            <el-image
              style="width: 100%; height: 100%"
              :src="require('../assets/edit_comment@1x.png')"
              fit="scale-down"
            ></el-image>
          </div>
          <div class="item_text">
            <div class="item_text_title">
              <span>贴心的一对一24H客服服务</span>
            </div>
            <div class="item_text_content">
              <span>售前、售后随时随地帮你解决问题</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Index",
  components: {},
  data() {
    return {
      active_tab: -1,
    };
  },
  methods: {
    active(index) {
      this.active_tab = index;
    },
    unActive() {
      this.active_tab = -1;
    },
    download() {
      window.open("https://wwa.lanzoui.com/b00t7a1be", "__blank");
    },
    help() {
      window.open("http://note.youdao.com/s/1eftz7TI", "__blank");
    },
    tool() {
      this.$message("敬请期待");
    },
    agent() {
      this.$alert("添加微信：15906218318 24小时客服咨询", "申请代理", {
        confirmButtonText: "确定",
      });
    },
    openSource() {
      this.$message("敬请期待");
    },
  },
};
</script>

<style lang="scss">
.index {
  width: 100%;
  height: 100%;

  .head {
    width: 100%;
    height: 80px;
    position: relative;

    .logo_content {
      position: absolute;
      width: 150px;
      height: 48px;
      left: 40px;
      top: 16px;

      .logo {
        width: 48px;
        height: 48px;

        img {
          width: 48px;
          height: 48px;
        }
      }

      .logo_text {
        position: absolute;
        left: 60px;
        top: 14px;
      }
    }

    .nav {
      position: absolute;
      width: 480px;
      height: 48px;
      right: 16px;
      top: 16px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .nav_func {
        padding: 10px;
        cursor: pointer;
        &.active {
          background-color: #2664f5;
          color: #ffffff;
        }
      }

      .nav_func span {
        font-weight: bold;
      }
    }
  }

  .center {
    top: 80px;
    width: 100%;
    height: calc(100% - 240px);
    min-height: 460px;
    display: flex;

    .center_left {
      flex: 4;
      display: flex;
      flex-direction: column;

      .up {
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .jtb {
          width: 80%;

          span {
            font-size: 5vh;
            font-weight: bold;
          }
        }
      }

      .down {
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        .test {
          margin-top: 8vh;
          width: 80%;
          display: flex;
          align-items: center;

          .test_button {
            width: 30vh;
            height: 10vh;
            background-color: #2664f5;
            border-radius: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            &:hover {
              background-color: #fd7b6e;
            }
            span {
              font-size: 4vh;
              color: #ffffff;
            }
          }

          .test_text {
            margin-left: 6vh;
            color: #6e8198;

            p {
              font-weight: 300;
            }
          }
        }
      }
    }

    .center_right {
      flex: 6;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -4vw;
        // top: -5vw;
      }
    }
  }

  .footer {
    height: 160px;
    width: 100%;
    display: flex;
    justify-content: center;

    .detail_list {
      height: 120px;
      width: calc(100% - 180px);
      display: flex;
      justify-content: space-between;

      .item {
        flex: 1;
        display: flex;

        .item_icon {
          width: 4vw;
          height: 4vw;
          background-color: #2664f5;
          border-radius: 16px;
        }

        .item_text {
          width: 14vw;
          height: 4vw;
          display: flex;
          flex-direction: column;

          .item_text_title {
            flex: 1;
            margin-left: 2vh;
            display: flex;
            align-items: flex-end;
            span {
              font-weight: bold;
            }
          }

          .item_text_content {
            flex: 1;
            margin-left: 2vh;

            span {
              font-size: 10px;
              color: #6e8198;
            }
          }
        }
      }
    }
  }
}
</style>
